import type React from 'react';
import type { Tracker } from '@yoursurprise/segment-analytics';
import { IntlProvider } from 'react-intl';
import type { SearchClient } from 'algoliasearch';
import type { InstantSearchServerState } from 'react-instantsearch-core';
import type { UiState } from 'instantsearch.js';
import TranslationProvider from '../../../general/Translation/TranslationProvider';
import type { Translations } from '../../../general/Translation/types/translations';
import type { WebshopContextData } from '../../../general/WebshopContext/WebshopContextProvider';
import WebshopContextProvider from '../../../general/WebshopContext/WebshopContextProvider';
import type { Components } from '../../../general/Components/ComponentTypes';
import ComponentRenderer from './ComponentRenderer';

export interface AppProps {
    algoliaServerState?: InstantSearchServerState;
    components: Components;
    contextData: WebshopContextData;
    currency: string;
    initialUiState?: UiState;
    locale: string;
    pageId: number;
    pageTypeSlug: string;
    photoGiftUrl: string;
    productIndexName: string;
    replicaIndexBase: string;
    searchClient: SearchClient;
    tracker?: Tracker | undefined;
    translations: Translations;
}

const App: React.FC<AppProps> = (
    {
        algoliaServerState,
        components,
        contextData,
        currency,
        initialUiState,
        locale,
        pageId,
        pageTypeSlug,
        photoGiftUrl,
        productIndexName,
        replicaIndexBase,
        searchClient,
        tracker,
        translations,
    },
) => (
    <IntlProvider locale={locale}>
        <TranslationProvider dictionary={translations}>
            <WebshopContextProvider data={contextData}>
                <ComponentRenderer
                    algoliaServerState={algoliaServerState}
                    pageTypeSlug={pageTypeSlug}
                    components={ components}
                    currency={currency}
                    locale={locale}
                    pageId={pageId}
                    photoGiftUrl={photoGiftUrl}
                    productIndexName={productIndexName}
                    replicaIndexBase={replicaIndexBase}
                    searchClient={searchClient}
                    initialUiState={initialUiState}
                    tracker={tracker}
                />
            </WebshopContextProvider>
        </TranslationProvider>
    </IntlProvider>
);
export default App;
