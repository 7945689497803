import type { IndexUiState } from 'instantsearch.js';

const updateSortBy = (state: IndexUiState, productIndexName: string, replicaIndexBase: string): IndexUiState => {
    const newUiState = {
        ...state,
    };
    const { sortBy } = newUiState;

    if (sortBy) {
        if (sortBy === 'relevance') {
            newUiState.sortBy = productIndexName;
        } else if (sortBy === 'price_asc' || sortBy === 'price_desc') {
            newUiState.sortBy = `${replicaIndexBase}-${sortBy}`;
        }
    }

    return newUiState;
};

export default updateSortBy;
